import React from "react";
import { Wheel } from "../../components/wheel";
import { Description } from "../../components/description";
import { CustomHelmet } from "../../components/customhelmet";
import { useTranslation } from "react-i18next";


export default function HomePage() {
    const { t } = useTranslation();

    return <React.Fragment><Wheel></Wheel>
        <Description title={t("title.homePage")} description={t("description.homePageDescription")}></Description>
        <CustomHelmet title={t("helmet.homePage.title")}
            description={t("helmet.homePage.description")}
            keywords={t("helmet.homePage.keywords")}
            ogTitle={t("helmet.homePage.title")}
            ogDescription={t("helmet.homePage.description")}
            ogImage={t("url.helmetImage")}
        />
        <div className="container">
            <div className="row text-start mt-5">
                <div className="row">
                    <div className="mt-5 text-center">
                        <h2 className="cc-h1">
                            {t("description.homePage.h0")}
                        </h2>
                    </div>
                    <div className="cc-desc text-center">
                        {t("description.homePage.p0")}
                    </div>
                    <div className="col-sm-6 text-center">
                        <img src={t("url.step1")} alt="" className="img-fluid"></img>
                    </div>
                    <div className="col-sm-6 d-flex align-items-center">
                        <div className="d-block">
                            <h5>{t("description.homePage.h1")}</h5>
                            <p>{t("description.homePage.p1")}</p>
                        </div>
                    </div>
                    <div className="col-sm-6 d-flex align-items-center">
                        <div className="d-block">
                            <h5>{t("description.homePage.h2")}</h5>
                            <p>{t("description.homePage.p2")}</p>
                        </div>
                    </div>
                    <div className="col-sm-6 text-center">
                        <img src={t("url.step2")} alt="" className="img-fluid"></img>
                    </div>
                    <div className="col-sm-6 text-center">
                        <img src={t("url.step3")} alt="" className="img-fluid"></img>
                    </div>
                    <div className="col-sm-6 d-flex align-items-center">
                        <div className="d-block">
                            <h5>{t("description.homePage.h3")}</h5>
                            <p>{t("description.homePage.p3")}</p>
                        </div>
                    </div>
                    <div className="col-sm-6 d-flex align-items-center">
                        <div className="d-block">
                            <h5>{t("description.homePage.h4")}</h5>
                            <p>{t("description.homePage.p4")}</p>
                        </div>
                    </div>
                    <div className="col-sm-6 text-center">
                        <img src={t("url.step4")} alt="" className="img-fluid"></img>
                    </div>
                    <div className="col-sm-6 text-center">
                        <img src={t("url.step5")} alt="" className="img-fluid"></img>
                    </div>
                    <div className="col-sm-6 d-flex align-items-center">
                        <div className="d-block">
                            <h5>{t("description.homePage.h5")}</h5>
                            <p>{t("description.homePage.p5")}</p>
                        </div>
                    </div>
                </div>

                <div className="row text-start mt-5">
                    <div className="mt-5 text-center">
                        <h2 className="cc-h1">
                            {t("description.homePage.h6")}
                        </h2>
                    </div>

                    <div className="col-sm-6 d-flex">
                        <div className="col-sm-6 text-center mt-3">
                            <a href={t("url.whatToEat")}><img src={t("url.foodimage")} alt="" className="img-fluid-custom"></img></a>
                        </div>
                        <div className="col-sm-6 d-flex align-items-center mt-3">
                            <div className="d-block">
                                <h5><a href={t("url.whatToEat")} className="cc-link">{t("description.homePage.h7")}</a></h5>
                                <p>{t("description.homePage.p6")}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 d-flex">
                        <div className="col-sm-6 text-center mt-3">
                            <a href={t("url.yesno")}><img src={t("url.yesnoimage")} alt="" className="img-fluid-custom"></img></a>
                        </div>
                        <div className="col-sm-6 d-flex align-items-center mt-3">
                            <div className="d-block">
                                <h5><a href={t("url.yesno")} className="cc-link">{t("description.homePage.h8")}</a></h5>
                                <p>{t("description.homePage.p7")}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 d-flex">
                        <div className="col-sm-6 text-center mt-5">
                            <a href={t("url.truthordare")}><img src={t("url.truthordareimage")} alt="" className="img-fluid-custom"></img></a>
                        </div>
                        <div className="col-sm-6 d-flex align-items-center mt-3">
                            <div className="d-block">
                                <h5><a href={t("url.truthordare")} className="cc-link">{t("description.homePage.h9")}</a></h5>
                                <p>{t("description.homePage.p8")}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 d-flex">
                        <div className="col-sm-6 text-center mt-5">
                            <a href={t("url.lol")}><img src={t("url.lolimage")} alt="" className="img-fluid-custom"></img></a>
                        </div>
                        <div className="col-sm-6 d-flex align-items-center mt-3">
                            <div className="d-block">
                                <h5><a href={t("url.lol")} className="cc-link">{t("description.homePage.h10")}</a></h5>
                                <p>{t("description.homePage.p9")}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 text-center mt-5 mb-5">
                        <a href={t("url.allWheels")} className="btn btn-primary">{t("title.showAllWheel")}</a>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
}