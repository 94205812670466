import { useEffect, useState } from 'react';
import './localization/config';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import { Route, Routes, useLocation } from 'react-router-dom';
import { AboutUs } from './pages/aboutus';
import { Privacy } from './pages/privacy';
import { ContactUs } from './pages/contactus';
import { AllWheelPage } from './pages/allwheelpage';
import { IGenericPageOptions, IMenuItem } from './models/interfaces';
import { Menu } from './components/menu';
import HomePage from './pages/homepage';
import { GenericPage } from './pages/genericpage';
import { NumberWheel } from './components/numberwheel';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import ReactGA from "react-ga4";
import config from './configurations/common.json';

function App() {
  const { t } = useTranslation();
  const [menu, setMenu] = useState<IMenuItem[]>([]);
  const { pathname } = useLocation();

  useEffect(() => {
    loadMenu();
    const ga = t("configuration.ga");
    if (ga) {
      ReactGA.initialize(ga);
    }
    const script = document.createElement('script');

    script.src = t("configuration.adsense");
    script.async = true;
    script.crossOrigin = "anonymous";

    if (script.src) {
      document.head.appendChild(script);
    }

    return () => {
      if (script.src) {
        document.head.removeChild(script);
      }
    }
  }, [])

  const loadMenu = async () => {
    const spinReadyConfigurations = await import('./configurations/' + config.language + '/spinready.json');
    const foodConfiguration = await import('./configurations/' + config.language + '/foods.json');

    const menuItems: IMenuItem[] = [
      {
        url: "/",
        text: "",
        children: <img src={t("url.mainLogo")} alt="logo" />,
        className: "d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none",
        page: <HomePage />
      },
      {
        url: t("url.whatToEat"),
        text: t("title.whatToEat"),
        page: <GenericPage wheelConfiguration={foodConfiguration.wheelConfiguration}
          descriptionConfiguration={foodConfiguration.descriptionConfiguration}
          helmetConfiguration={foodConfiguration.helmetConfiguration} />
      },
      {
        url: t("url.numberWheel"),
        text: t("title.numberWheel"),
        page: <NumberWheel />
      },
      {
        url: t("url.allWheels"),
        text: t("title.allWheels"),
        page: <AllWheelPage />
      },
      {
        url: t("url.aboutUs"),
        text: t("title.aboutUs"),
        page: <AboutUs />,
        hiddenMenu: true
      },
      {
        url: t("url.privacy"),
        text: t("title.privacy"),
        page: <Privacy />,
        hiddenMenu: true
      },
      {
        url: t("url.contactUs"),
        text: t("title.contactUs"),
        page: <ContactUs />,
        hiddenMenu: true
      }
    ];

    for (let index = 0; index < spinReadyConfigurations.length; index++) {
      const spinReadyConfiguration: IGenericPageOptions = spinReadyConfigurations[index];
      menuItems.push({
        url: spinReadyConfiguration.url || "",
        text: "",
        hiddenMenu: true,
        page: <GenericPage wheelConfiguration={spinReadyConfiguration.wheelConfiguration}
          descriptionConfiguration={spinReadyConfiguration.descriptionConfiguration}
          helmetConfiguration={spinReadyConfiguration.helmetConfiguration}
        />
      });
    }

    setMenu(menuItems);
  }

  return (
    <div className="App">
      <div className="container py-4">
        <header className="d-flex flex-wrap w-100 pb-3 mb-4 border-bottom">
          <Menu items={menu} activeRoute={pathname}></Menu>
        </header>
        <Routes>
          {menu.map((menuitem: IMenuItem, index: number) => {
            return <Route key={index} path={menuitem.url} element={menuitem.page} />
          })}
        </Routes>
        <footer className="d-flex flex-wrap justify-content-between align-items-center border-top">
          <p className="col-md-4 mb-0 text-muted text-start">{t("title.credit")}</p>
          <ul className="nav col-md-4 justify-content-end">
            <li className="nav-item"><a href={t("url.aboutUs")} className="nav-link px-2 text-muted">{t("title.aboutUs")}</a></li>
            <li className="nav-item"><a href={t("url.privacy")} className="nav-link px-2 text-muted">{t("title.privacy")}</a></li>
            <li className="nav-item"><a href={t("url.contactUs")} className="nav-link px-2 text-muted">{t("title.contactUs")}</a></li>
          </ul>
        </footer>
      </div>
      <ToastContainer></ToastContainer>
    </div>
  );
}

export default App;
