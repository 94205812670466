import React from "react";
import { useTranslation } from "react-i18next";

export function AboutUs() {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <h1 className="cc-h1">{t("title.aboutUs")}</h1>
            <div style={{ textAlign: "justify" }}>
                <p>{t("description.aboutUs.p1")}</p>

                <p>{t("description.aboutUs.p2")}</p>

                <p>{t("description.aboutUs.p3")}</p>

                <p>{t("description.aboutUs.p4")}</p>

                <p>{t("description.aboutUs.p5")}</p>

                <p>{t("description.aboutUs.p6")}</p>

                <strong>{t("description.aboutUs.devTeam")}</strong><br /><i>OguzKK, relre</i>
            </div>
        </React.Fragment>);
}