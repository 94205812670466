import React from "react";
import { useTranslation } from "react-i18next";

export function ContactUs() {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <h1 className="cc-h1">{t("title.contactUs")}</h1>
            <div><strong>{t("description.email")}</strong>{t("configuration.email")}</div>
        </React.Fragment>);
}