import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './en/translation.json';
import trTranslation from './tr/translation.json';
import config from '../configurations/common.json';


i18next.use(initReactI18next).init({
    lng: config.language,
    debug: true,
    resources: {
        en: {
            enTranslation,
        },
        tr: {
            trTranslation,
        },
    },
    ns: config.language + "Translation"
});