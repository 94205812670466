import React from "react";
import { useTranslation } from "react-i18next";

export function AllWheelPage() {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <h1 className="cc-h1">{t("title.allWheels")}</h1>
            <div className="row">
                <div className="col-sm-6 mx-auto">
                    <div className="text-start mt-3">
                        <div className="group-header">{t("title.generalCategory")}</div>
                        <ul className="list-group">
                            <li className="list-group-item"><a href={t("url.numberWheel")} className="link-cc">{t("title.numberWheel")}</a></li>
                            <li className="list-group-item"><a href={t("url.yesno")} className="link-cc">{t("title.yesno")}</a></li>
                            <li className="list-group-item"><a href={t("url.truthordare")} className="link-cc">{t("title.truthordare")}</a></li>
                            <li className="list-group-item"><a href={t("url.numberWheel")} className="link-cc">{t("title.numberWheel")}</a></li>
                            <li className="list-group-item"><a href={t("url.moneyWheel")} className="link-cc">{t("title.moneyWheel")}</a></li>
                            <li className="list-group-item"><a href={t("url.alphabetWheel")} className="link-cc">{t("title.alphabetWheel")}</a></li>
                            <li className="list-group-item">{t("title.countryWheel")}<span className="badge bg-secondary ml-10">{t("title.soon")}</span></li>
                            <li className="list-group-item">{t("title.cityWheel")}<span className="badge bg-secondary ml-10">{t("title.soon")}</span></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-sm-6 mx-auto">
                    <div className="text-start mt-3">
                        <div className="group-header">{t("title.food")}</div>
                        <ul className="list-group">
                            <li className="list-group-item"><a href={t("url.whatToEat")} className="link-cc">{t("title.whatToEat")}</a></li>
                            <li className="list-group-item">{t("title.whichStarbucksCoffee")}<span className="badge bg-secondary ml-10">{t("title.soon")}</span></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row mt-5 mb-5">
                <div className="col-sm-6 mx-auto">
                    <div className="text-start mt-3">
                        <div className="group-header">{t("title.game")}</div>
                        <ul className="list-group">
                            <li className="list-group-item"><a href={t("url.lol")} className="link-cc">{t("title.lol")}</a></li>
                            <li className="list-group-item"><a href={t("url.valorant")} className="link-cc">{t("title.valorant")}</a></li>
                            <li className="list-group-item">{t("title.dota")}<span className="badge bg-secondary ml-10">{t("title.soon")}</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </React.Fragment>);
}