import "./style.scss";
import React, { createRef, useEffect, useState } from 'react';
import { IDoorProps, IDoorsProps, IWheelProps, } from '../../models/interfaces';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlay } from '@fortawesome/free-solid-svg-icons/faCirclePlay';
import { Description } from "../description";
import { useTranslation } from "react-i18next";
import { CustomHelmet } from "../customhelmet";


export function NumberWheel(props: IWheelProps) {
    const [isWheelSpining, setIsWheelSpining] = useState<boolean>(false);
    const [doorCount, setDoorCount] = useState(3);
    const { t } = useTranslation();

    useEffect(() => {
    }, [])



    const spinWheel = () => {
        setIsWheelSpining(true);
    }

    const spinOver = () => {
        setIsWheelSpining(false);
    }

    const getBackgroundSize = () => {
        return { backgroundSize: `${(doorCount * 100) / 10}% 100%` };
    };

    return (
        <div className={"number-wheel"}>
            <CustomHelmet title={t("helmet.numberWheel.title")}
                description={t("helmet.numberWheel.description")}
                keywords={t("url.keywords")}
                ogTitle={t("helmet.numberWheel.title")}
                ogDescription={t("helmet.numberWheel.description")}
                ogImage={t("url.helmetImage")}
            />
            <div className="row">
                <div className="col-sm-12 range-picker">
                    <input
                        disabled={isWheelSpining}
                        type="range"
                        min="1"
                        max={"10"}
                        onChange={(e) => setDoorCount(parseInt(e.target.value))}
                        style={getBackgroundSize()}
                        value={doorCount}
                    />
                </div>
                <div className="col-sm-12">
                    <Doors doorCount={doorCount} spinning={isWheelSpining} spinOver={spinOver}></Doors>
                    <div className={"playButton mt-5 " + (isWheelSpining ? "playButton-disabled" : "")} onClick={spinWheel}>
                        {t("description.spin")}
                        <FontAwesomeIcon className="playButton-icon" icon={faCirclePlay} />
                    </div>
                </div>
            </div>
            <Description title={t("title.randomNumberWheel")} description={t("description.randomNumberWheel")}></Description>
        </div>
    );
}

function Doors(props: IDoorsProps) {
    const [doorRefs, setDoorRefs] = useState<React.RefObject<HTMLDivElement>[]>([]);
    const [size, setSize] = useState<number>(100);

    const clearDoors = () => {
        for (const doorRef of doorRefs) {
            if (doorRef.current) {
                doorRef.current.style.transition = 'none';
                doorRef.current.style.transform = 'translateY(0px)';
            }
        }
    }

    useEffect(() => {
        const refs: React.RefObject<HTMLDivElement>[] = [];
        for (let index = 0; index < props.doorCount; index++) {
            refs.push(createRef());
        }
        setDoorRefs([...refs]);
        clearDoors();
        const doorSize = Math.min(((window.screen.width * 0.8) - ((props.doorCount - 1) * 10)) / props.doorCount, 100);
        setSize(Math.floor(doorSize));
    }, [props.doorCount]);


    const spin = () => {
        if (props.spinning) {
            clearDoors();
            setTimeout(() => {
                for (const doorRef of doorRefs) {
                    if (doorRef.current) {
                        const duration = Math.floor(Math.random() * 91 + 10);
                        doorRef.current.style.transition = 'all ease 5s';
                        doorRef.current.style.transform = 'translateY(-' + duration * size + 'px)';
                    }
                }
                props.spinOver();
            }, 500);
        }
    }

    useEffect(() => {
        spin();
    }, [props.spinning])

    return (
        <div className="doors">
            {doorRefs.map((doorRef, index) => {
                return <Door key={index} mainDivRef={doorRef} size={size}></Door>;
            })}
        </div>
    );
}

function Door(props: IDoorProps) {
    const [digits, setDigits] = useState<number[]>([]);

    useEffect(() => {
        if (digits.length === 0) {
            for (let multi = 0; multi < 10; multi++) {
                for (let index = 0; index < 10; index++) {
                    digits.push(index);
                }
            }
            setDigits([...digits]);
        }
    }, []);

    return (
        <div className="door" style={{ width: props.size + 'px', height: (props.size * 1.1) + 'px' }}>
            <div className="boxes" ref={props.mainDivRef}>
                {digits.map((digit, index) => {
                    return <div key={index} style={{
                        width: props.size + 'px',
                        height: props.size + 'px',
                        fontSize: props.size + 'px'
                    }} className="box">{digit}</div>;
                })}
            </div>
        </div>
    );
}
