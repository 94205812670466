export const componentToHex = (c: number) => {
    let hex = Math.floor(c).toString(16);
    return hex.length === 1 ? "0" + hex : hex;
}

export const rgbToHex = (r: number, g: number, b: number) => {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export const stringFormat = (formatString: string, params: any[]) => {
    for (let index = 0; index < params.length; index++) {
        formatString = formatString.replace("{" + index + "}", params[index]);
    }
    return formatString;
}